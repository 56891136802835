import { useStore } from "@/store/main";
import { UserModel } from "@shared/models/User";
import { GameName } from "@shared/models/Game";
import { ContactFormSubmission } from "@shared/models/ContactForm";
import { CreateNotificationModal, NotificationModel } from "@shared/models/Notifications";
import { ShopItemModel } from "@shared/models/Shop";
import {
  CreateNewsArticleModel,
  NewsArticleModel,
  NewsModel,
  UpdateNewsArticleModel,
} from "@shared/models/News";
import { ShopReportModel, UserReportModel } from "@shared/models/Reports";
import { CreateRewardModel, RewardModel } from "@shared/models/Reward";
import { EmailName } from "@shared/models/AdminEmail";

const url = process.env.VUE_APP_SCUFFED_GAMES_API_URL;

export interface ErrorResponse {
  success: false;
  reason: string;
}

export interface SuccessResponse<T> {
  success: true;
  data: T;
}

async function request<T>(
  path: string,
  method: string,
  body?: any
): Promise<ErrorResponse | SuccessResponse<T>> {
  const json = JSON.stringify(body);

  try {
    const res = await fetch(`${url}${path}`, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: json || undefined,
    });

    return res.json().catch(() => ({ success: false, reason: res.statusText }));
  } catch (error) {
    return { success: false, reason: String(error) };
  }
}

export async function login(username: string, password: string) {
  const userStore = useStore();
  userStore.isLoggingIn = true;

  const res = await request<UserModel>("/auth/login/password", "POST", {
    username,
    password,
  });
  if (res.success) {
    userStore.user = res.data;

    // authenticate with game server
    const store = useStore();
    await store.waitForSocketConnection();
    await store.socketEmitWithAck("scuffedGames:authenticate-password", username, password);
  }

  userStore.isLoggingIn = false;

  return res;
}

export async function loginWithToken() {
  const userStore = useStore();
  userStore.isLoggingIn = true;

  const res = await request<UserModel>("/auth/login/token", "POST");
  if (res.success) {
    userStore.user = res.data;

    // authenticate with game server
    const store = useStore();
    await store.waitForSocketConnection();
    await store.socketEmitWithAck("scuffedGames:authenticate");
  }

  return res;
}

export async function getContactFormSubmissions() {
  return request<ContactFormSubmission[]>(`/contact?game=${GameName.SCUFFED_UNO}`, "GET");
}

export async function deleteContactFormSubmission(id: number) {
  return request<ContactFormSubmission[]>(`/contact/${id}`, "DELETE");
}

export async function getUser(id: number) {
  return request<UserModel>(`/user/${id}`, "GET");
}

export async function broadcastNotification(notification: CreateNotificationModal) {
  return request<{ sent: number; failed: number }>("/notifications/broadcast", "POST", notification);
}

export async function getNews(page: number, numPerPage: number) {
  return request<NewsModel>(
    `/news?page=${page}&game=${GameName.SCUFFED_UNO}&numPerPage=${numPerPage}`,
    "GET"
  );
}

export async function createNews(news: CreateNewsArticleModel) {
  return request<NewsArticleModel>("/news", "POST", news);
}

export async function updateNews(id: number, news: UpdateNewsArticleModel) {
  return request<NewsArticleModel>(`/news/${id}`, "PATCH", news);
}

export async function deleteNews(id: number) {
  return request<NewsArticleModel>(`/news/${id}`, "DELETE");
}

export async function getUserReport(daysAgo: number) {
  return request<UserReportModel>(`/reports/user?daysAgo=${daysAgo}`, "GET");
}

export async function getShopReport() {
  return request<ShopReportModel>("/reports/shop", "GET");
}

export async function getShopItems() {
  return request<ShopItemModel[]>("/shop/items", "GET");
}

export async function createReward(reward: CreateRewardModel) {
  return request<RewardModel>("/reward", "POST", reward);
}

export async function getUserIdByUsername(username: string) {
  return request<number>(`/user/id/${username}`, "GET");
}

export async function getAdminEmailNames() {
  return request<string[]>("/admin/email/names", "GET");
}

export async function sendAdminEmail(
  emailName: EmailName,
  usernames?: string[],
  daysSinceLastActive?: number
) {
  return request<number>("/admin/email/send", "POST", { emailName, usernames, daysSinceLastActive });
}
