<script lang="ts">
import { defineComponent, ref } from "vue";
import SButton from "scuffed-ui/src/components/Button/SButton/SButton.vue";
import SFormLabel from "scuffed-ui/src/components/Form/SFormLabel/SFormLabel.vue";
import SHeading from "scuffed-ui/src/components/Heading/SHeading/SHeading.vue";
import SInputSelect from "scuffed-ui/src/components/Input/SInputSelect/SInputSelect.vue";
import SInputArea from "scuffed-ui/src/components/Input/SInputArea/SInputArea.vue";
import { RewardData, RewardType } from "@shared/models/Reward";
import { createReward, getUserIdByUsername } from "@/api/scuffedGames";
import ToastController from "scuffed-ui/src/components/Toast/SToastController/ToastController";
import Toast from "scuffed-ui/src/components/Toast/SToastController/Toast";

export default defineComponent({
  name: "UCreateReward",
  components: {
    SHeading,
    SButton,
    SFormLabel,
    SInputSelect,
    SInputArea,
  },
  setup() {
    const users = ref("");
    const rewardType = ref(RewardType.COINS);
    const rewardData = ref("");

    const isLoading = ref(false);

    const createRewards = async () => {
      isLoading.value = true;

      const usernames = users.value.split(",").map((u) => u.trim());
      const data = JSON.parse(rewardData.value) as RewardData;

      const res = await Promise.all(
        usernames.map(async (username) => {
          const userId = await getUserIdByUsername(username);
          if (!userId.success) {
            return false;
          }

          const res = await createReward({ userId: userId.data, type: rewardType.value, data });
          if (!res.success) {
            return false;
          }

          return {
            username,
            rewardId: res.data.id,
          };
        })
      );

      const successCount = res.filter((r) => r).length;
      ToastController.addToast(
        new Toast(
          `Created rewards. Success: ${successCount}, Failed: ${res.length - successCount}`,
          false,
          3000
        )
      );

      alert(
        "Rewards: " +
          res
            .filter((r) => r)
            .map((r) => `${(r as any).username} - ${(r as any).rewardId}`)
            .join(",\n")
      );

      isLoading.value = false;
    };

    return {
      users,
      rewardType,
      rewardData,
      isLoading,

      createRewards,

      RewardType,
    };
  },
});
</script>

<template>
  <section>
    <SHeading class="text-4xl" :level="1">Create Rewards</SHeading>

    <form class="flex flex-col gap-4" @submit.prevent="createRewards">
      <div class="flex flex-col flex-grow">
        <SFormLabel required>Reward Type</SFormLabel>
        <SInputSelect v-model="rewardType" :options="Object.keys(RewardType)" required />
      </div>

      <pre class="pl-0 ml-0 mt-4">
        XP Reward:
            {
              "xp": 100 (integer >= 0)
            }
        
        Coins Reward:
            {
              "coins": 100 (integer >= 0)
            }
        
        Shop Item Reward:
            {
              "shopItemId": 1 (positive integer) (must be valid shop item id)
            }
      </pre>

      <div class="flex flex-col">
        <SFormLabel required>Reward Data (must be valid json object)</SFormLabel>
        <SInputArea v-model="rewardData" type="string" placeholder="Enter reward data..." required />
      </div>

      <div class="flex flex-col">
        <SFormLabel required>Usernames (comma separated)</SFormLabel>
        <SInputArea v-model="users" type="string" placeholder="Enter reward data..." required />
      </div>

      <SButton class="h-16" type="submit"> {{ isLoading ? "Loading..." : "Create Rewards" }} </SButton>
    </form>
  </section>
</template>

<style lang="scss" scoped></style>
