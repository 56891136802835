<script lang="ts">
import { defineComponent, onBeforeMount, ref } from "vue";
import { useIntervalFn } from "@vueuse/core";
import { useStore } from "@/store/main";
import { Room } from "@/gameTypes";

import SSpinnerDots from "scuffed-ui/src/components/Spinner/SSpinnerDots/SSpinnerDots.vue";
import SButtonText from "scuffed-ui/src/components/Button/SButtonText/SButtonText.vue";
import SModal from "scuffed-ui/src/components/Modal/SModal/SModal.vue";
import URoomChat from "./URoomChat.vue";
import URoomPlayers from "./URoomPlayers.vue";

export default defineComponent({
  name: "URoomList",
  components: {
    SSpinnerDots,
    SButtonText,
    SModal,
    URoomChat,
    URoomPlayers,
  },
  setup() {
    const store = useStore();
    const isLoading = ref(true);

    const rooms = ref<Room[]>([]);
    const openChat = ref<Room>();
    const openPlayers = ref<Room>();
    const openJSON = ref<Room>();

    const interval = useIntervalFn(
      () => {
        store.socket?.emit("admin:get-rooms", (r: Room[]) => {
          rooms.value = r;
          isLoading.value = false;

          if (openChat.value)
            openChat.value = rooms.value.find(
              (r) => r.id === openChat.value?.id
            );

          if (openPlayers.value)
            openPlayers.value = rooms.value.find(
              (r) => r.id === openPlayers.value?.id
            );

          if (openJSON.value)
            openJSON.value = rooms.value.find(
              (r) => r.id === openJSON.value?.id
            );
        });
      },
      1000,
      { immediate: true }
    );

    return {
      isLoading,
      rooms,
      openChat,
      openPlayers,
      openJSON,
    };
  },
});
</script>

<template>
  <div class="relative flex flex-col w-full h-full">
    <s-spinner-dots
      v-if="isLoading"
      class="absolute top-0 left-0 z-10 w-full h-full"
    />

    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Host</th>
          <th>Players</th>
          <th>Rules</th>
          <th>Empty</th>
          <th>Started</th>
          <th>Public</th>
          <th>Actions</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="room in rooms" :key="room.id">
          <td>{{ room.id }}</td>
          <td>{{ room.host.username }}</td>
          <td>{{ room.players.length }} / 8</td>
          <td>
            <ul>
              <li
                v-for="k in Object.keys(room.settings).filter(
                  (k) => room.settings[k]
                )"
                :key="k"
              >
                {{ k }}: {{ room.settings[k] }}
              </li>
            </ul>
            {{}}
          </td>
          <td>
            {{ room.isRoomEmpty }}
          </td>
          <td>
            {{ room.started }}
          </td>
          <td>
            {{ room.settings.public }}
          </td>
          <td class="flex flex-row justify-between px-3 gap-3 flex-wrap">
            <s-button-text class="mb-0.5" @click="openChat = room">
              Chat
            </s-button-text>
            <s-button-text class="mb-0.5" @click="openPlayers = room">
              Players
            </s-button-text>
            <s-button-text @click="($event) => (openJSON = room)">
              JSON
            </s-button-text>
          </td>
        </tr>
      </tbody>
    </table>

    <s-modal
      class="max-w-5xl w-full h-[50rem] max-h-full"
      :open="!!openChat"
      @close="openChat = undefined"
    >
      <u-room-chat v-if="!!openChat" :room="openChat" />
    </s-modal>

    <s-modal
      class="max-w-5xl w-full h-[50rem] max-h-full"
      :open="!!openPlayers"
      @close="openPlayers = undefined"
    >
      <u-room-players v-if="!!openPlayers" :room="openPlayers" />
    </s-modal>

    <s-modal
      class="max-w-5xl w-full h-[50rem] max-h-full"
      :open="!!openJSON"
      @close="openJSON = undefined"
    >
      <pre class="p-4 overflow-y-scroll h-full scrollbar">
        {{ openJSON ? JSON.stringify(openJSON, null, 2) : "" }}
      </pre>
    </s-modal>
  </div>
</template>

<style lang="scss" scoped>
table {
  tr {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 0.5rem;
    margin-bottom: 1rem;
  }

  td {
    text-align: center;
  }
}
</style>
