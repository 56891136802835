"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameDisplayName = exports.GameName = void 0;
var GameName;
(function (GameName) {
    GameName["SCUFFED_UNO"] = "SCUFFED_UNO";
})(GameName || (exports.GameName = GameName = {}));
var GameDisplayName;
(function (GameDisplayName) {
    GameDisplayName["SCUFFED_UNO"] = "Scuffed Uno";
})(GameDisplayName || (exports.GameDisplayName = GameDisplayName = {}));
