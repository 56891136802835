import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";

// import fonts
import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/800.css";

// import tailwind
import "./assets/tailwind.css";
import { createSocket } from "./api/socket";

const app = createApp(App);
app.use(router);
app.use(createPinia());

app.mount("#app");
createSocket();
