import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex items-center gap-4" }
const _hoisted_2 = { class: "flex items-center gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_s_spinner_dots = _resolveComponent("s-spinner-dots")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_s_heading = _resolveComponent("s-heading")!
  const _component_u_card = _resolveComponent("u-card")!

  return (_openBlock(), _createBlock(_component_u_card, { class: "p-4 flex items-center justify-around relative" }, {
    default: _withCtx(() => [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_s_spinner_dots, {
            key: 0,
            class: "absolute top-0 left-0 rounded-lg z-10 w-full h-full"
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Icon, {
          class: "w-12 h-12 max-h-full text-primary-600",
          icon: _ctx.icons.player
        }, null, 8, ["icon"]),
        _createVNode(_component_s_heading, {
          level: 1,
          class: "text-5xl"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.players) + " Online", 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Icon, {
          class: "w-12 h-12 max-h-full text-secondary-600",
          icon: _ctx.icons.room
        }, null, 8, ["icon"]),
        _createVNode(_component_s_heading, {
          level: 1,
          class: "text-5xl"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.rooms) + " Online", 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}