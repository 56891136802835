import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "w-full h-full flex flex-col gap-3 dt:gap-5" }
const _hoisted_2 = { class: "w-full h-[60vh] dt:h-full scrollbar overflow-y-scroll overflow-x-hidden pr-3 dt:pr-5 flex flex-col-reverse gap-4" }
const _hoisted_3 = { class: "text-neutral-50 break-words" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_s_input_text = _resolveComponent("s-input-text")!
  const _component_s_button = _resolveComponent("s-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chat, (m, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          class: _normalizeClass(["p-4 rounded-lg w-max max-w-[45%] min-w-[16rem] relative mb-5", {
          'bg-secondary-500 mr-auto': m.id !== _ctx.yourId,
          'bg-primary-600 ml-auto': m.id === _ctx.yourId,
        }])
        }, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(m.text), 1),
          _createElementVNode("p", {
            class: _normalizeClass(["absolute top-full text-neutral-600 text-sm font-medium", { 'right-0': m.id !== _ctx.yourId, 'left-0': m.id === _ctx.yourId }])
          }, _toDisplayString(m.username) + ", " + _toDisplayString(new Date(m.time).toTimeString().substr(0, 5)), 3)
        ], 2))
      }), 128))
    ]),
    _createElementVNode("form", {
      class: "flex gap-3",
      onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.sendMessage && _ctx.sendMessage(...args)), ["prevent"]))
    }, [
      _createVNode(_component_s_input_text, {
        class: "flex-grow text-lg py-4 min-w-0",
        modelValue: _ctx.message,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.message) = $event)),
        name: "message",
        placeholder: "Type message here...",
        maxlength: "300"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_s_button, { type: "submit" }, {
        default: _withCtx(() => [
          _createTextVNode("Send")
        ]),
        _: 1
      })
    ], 32)
  ]))
}