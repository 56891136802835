<script lang="ts">
import { createNews, deleteNews, getNews, updateNews } from "@/api/scuffedGames";
import { NewsArticleModel, NewsModel } from "@shared/models/News";
import { defineComponent, ref, watch } from "vue";
import ToastController from "scuffed-ui/src/components/Toast/SToastController/ToastController";
import Toast from "scuffed-ui/src/components/Toast/SToastController/Toast";
import SSpinnerDots from "scuffed-ui/src/components/Spinner/SSpinnerDots/SSpinnerDots.vue";
import SButtonText from "scuffed-ui/src/components/Button/SButtonText/SButtonText.vue";
import SButton from "scuffed-ui/src/components/Button/SButton/SButton.vue";
import { Icon } from "@iconify/vue";
import chevronLeftIcon from "@iconify-icons/feather/chevron-left";
import chevronRightIcon from "@iconify-icons/feather/chevron-right";
import SModal from "scuffed-ui/src/components/Modal/SModal/SModal.vue";
import SFormLabel from "scuffed-ui/src/components/Form/SFormLabel/SFormLabel.vue";
import SInputText from "scuffed-ui/src/components/Input/SInputText/SInputText.vue";
import SInputCheckbox from "scuffed-ui/src/components/Input/SInputCheckbox/SInputCheckbox.vue";
import SInputArea from "scuffed-ui/src/components/Input/SInputArea/SInputArea.vue";
import { GameDisplayName, GameName } from "@shared/models/Game";

export default defineComponent({
  name: "UNews",
  components: {
    SSpinnerDots,
    SButtonText,
    SButton,
    Icon,
    SModal,
    SFormLabel,
    SInputText,
    SInputCheckbox,
    SInputArea,
  },
  setup() {
    const news = ref<NewsModel>();
    const page = ref(1);
    const isLoading = ref(false);
    const openArticle = ref<NewsArticleModel>();

    watch(
      page,
      async (newPage) => {
        isLoading.value = true;
        const res = await getNews(newPage, 10);
        isLoading.value = false;

        if (!res.success) {
          ToastController.addToast(new Toast(`Failed to get news. Error: ${res.reason}`, true, 3000));
          return;
        }

        news.value = res.data;
      },
      { immediate: true }
    );

    const deleteArticle = async (id: number) => {
      if (!news.value) return;

      const res = await deleteNews(id);
      if (!res.success) {
        ToastController.addToast(new Toast(`Failed to delete article. Error: ${res.reason}`, true, 3000));
        return;
      }

      news.value.articles = news.value.articles.filter((a) => a.id !== id);
      ToastController.addToast(new Toast("Article deleted", true, 3000));
    };

    const updateArticle = async () => {
      if (!openArticle.value || !news.value) return;

      const res = await updateNews(openArticle.value.id, {
        title: openArticle.value.title,
        content: openArticle.value.content,
        date: new Date(openArticle.value.date),
        game: openArticle.value.game,
        isActive: openArticle.value.isActive,
      });
      if (!res.success) {
        ToastController.addToast(new Toast(`Failed to update article. Error: ${res.reason}`, true, 3000));
        return;
      }

      news.value.articles = news.value.articles.map((a) => (a.id === openArticle.value?.id ? res.data : a));
      ToastController.addToast(new Toast("Article updated", true, 3000));
    };

    const createArticle = async () => {
      if (!news.value) return;

      const res = await createNews({
        title: "New Article",
        content: "",
        date: new Date(),
        game: GameName.SCUFFED_UNO,
        isActive: false,
      });
      if (!res.success) {
        ToastController.addToast(new Toast(`Failed to create article. Error: ${res.reason}`, true, 3000));
        return;
      }

      news.value.articles.unshift(res.data);
      ToastController.addToast(new Toast("Article created", true, 3000));
    };

    return {
      news,
      page,
      isLoading,
      openArticle,

      chevronLeftIcon,
      chevronRightIcon,

      deleteArticle,
      updateArticle,
      createArticle,
    };
  },
});
</script>

<template>
  <div class="relative flex flex-col w-full h-full">
    <s-spinner-dots v-if="isLoading || !news" class="absolute top-0 left-0 z-10 w-full h-full" />

    <div v-else class="pr-4">
      <s-button @click="createArticle" class="h-12 w-full mb-8">Create Article</s-button>

      <table class="w-full">
        <thead>
          <tr>
            <th>Title</th>
            <th>Content</th>
            <th>Live Date</th>
            <th>Created On</th>
            <th>Active</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="a in news.articles" :key="a.id">
            <td class="max-w-64 whitespace-nowrap overflow-hidden text-ellipsis">{{ a.title }}</td>
            <td class="max-w-md whitespace-nowrap overflow-hidden text-ellipsis">{{ a.content }}</td>
            <td>{{ new Date(a.date).toISOString().split("T")[0] }}</td>
            <td>{{ new Date(a.createdAt).toISOString().split("T")[0] }}</td>
            <td>{{ a.isActive }}</td>
            <td>
              <s-button-text
                class="mb-0.5 mr-4"
                @click="openArticle = { ...a, date: new Date(a.date).toISOString().split('T')[0] }"
              >
                Edit
              </s-button-text>

              <s-button-text class="mb-0.5" purpose="danger" @click="deleteArticle(a.id)">
                Delete
              </s-button-text>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="grid grid-cols-3 w-48 place-items-center mx-auto mt-8">
        <s-button @click="page--" :disabled="page === 1" class="w-10 h-10 !p-0 disabled:bg-neutral-400">
          <Icon :icon="chevronLeftIcon" class="w-6 h-6" />
        </s-button>

        <p class="text-lg font-semibold text-neutral-700">{{ page }}</p>

        <s-button
          @click="page++"
          :disabled="news?.page === news?.numPages"
          class="w-10 h-10 disabled:bg-neutral-400 !p-0"
        >
          <Icon :icon="chevronRightIcon" class="w-6 h-6" />
        </s-button>
      </div>

      <s-modal
        v-if="openArticle"
        class="max-w-5xl w-full h-[50rem] max-h-full overflow-y-scroll scrollbar"
        :open="!!openArticle"
        @close="openArticle = undefined"
      >
        <form @submit.prevent="updateArticle()" class="flex flex-col gap-3 min-h-full">
          <div class="flex flex-col gap-0.5">
            <SFormLabel>Title</SFormLabel>
            <SInputText v-model="openArticle.title" />
          </div>

          <div class="flex flex-col gap-0.5">
            <SFormLabel>Content</SFormLabel>
            <SInputArea v-model="openArticle.content" class="min-h-72" resizeable />
          </div>

          <div class="flex flex-col gap-0.5">
            <SFormLabel>Date</SFormLabel>
            <SInputText v-model="openArticle.date" type="date" />
          </div>

          <div class="flex flex-col gap-0.5">
            <SFormLabel>Active</SFormLabel>
            <SInputCheckbox v-model="openArticle.isActive" />
          </div>

          <SButton type="submit" class="h-16 mt-auto">Update</SButton>
        </form>
      </s-modal>
    </div>
  </div>
</template>

<style lang="scss" scoped>
table {
  td {
    text-align: center;
    padding: 1rem 1rem;
  }
}
</style>
