import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Teleport as _Teleport, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_s_spinner_dots = _resolveComponent("s-spinner-dots")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_s_toast_controller = _resolveComponent("s-toast-controller")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isConnected)
      ? (_openBlock(), _createBlock(_component_s_spinner_dots, {
          key: 0,
          class: "absolute z-50 top-0 left-0 w-full h-full"
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_s_toast_controller, { class: "z-50 left-0" })
    ]))
  ], 64))
}