import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["h-full flex-grow px-4 rounded-lg flex items-center justify-center transition-colors duration-300", {
      'bg-neutral-300 text-neutral-500 hover:bg-neutral-400 hover:text-neutral-600':
        !_ctx.selected,
      'bg-secondary-200 text-secondary-500 pointer-events-none': _ctx.selected,
    }])
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 0,
          icon: _ctx.icon,
          class: "w-8 h-8"
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}