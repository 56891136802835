<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "UCard",
  components: {},
});
</script>

<template>
  <div class="bg-neutral-50 rounded-lg text-neutral-700">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped></style>
