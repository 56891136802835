<script lang="ts">
import { defineComponent, onBeforeMount, ref } from "vue";
import { login as loginScuffedGames } from "@/api/scuffedGames";

import SHeadingGradient from "scuffed-ui/src/components/Heading/SHeadingGradient/SHeadingGradient.vue";
import SFormLabel from "scuffed-ui/src/components/Form/SFormLabel/SFormLabel.vue";
import SFormMessage from "scuffed-ui/src/components/Form/SFormMessage/SFormMessage.vue";
import SInputText from "scuffed-ui/src/components/Input/SInputText/SInputText.vue";
import SInputPassword from "scuffed-ui/src/components/Input/SInputPassword/SInputPassword.vue";
import SButton from "scuffed-ui/src/components/Button/SButton/SButton.vue";
import SSpinnerDots from "scuffed-ui/src/components/Spinner/SSpinnerDots/SSpinnerDots.vue";
import UCard from "@/components/shared/UCard.vue";
import { useStore } from "@/store/main";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Home",
  components: {
    SHeadingGradient,
    SFormLabel,
    SFormMessage,
    SInputText,
    SInputPassword,
    SButton,
    SSpinnerDots,
    UCard,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const username = ref("");
    const password = ref("");
    const isLoading = ref(false);
    const loginError = ref("");

    const login = () => {
      if (isLoading.value || !username.value || !password.value) return;

      isLoading.value = true;

      store.socket?.emit("admin:auth", username.value, password.value, async (isAuthed: boolean) => {
        if (!isAuthed) {
          isLoading.value = false;
          loginError.value = "Error: Failed to authenticate user.";
        } else {
          await loginScuffedGames(username.value, password.value);

          store.isAuthed = true;
          store.username = username.value;
          router.push("/dashboard");
        }
      });
    };

    onBeforeMount(() => {
      if (store.isAuthed) router.replace("/dashboard");
    });

    return {
      username,
      password,
      isLoading,
      loginError,
      login,
    };
  },
});
</script>

<template>
  <main class="w-full h-full flex justify-center items-center">
    <u-card class="p-4 dt:p-8 max-w-xl w-full relative">
      <s-spinner-dots v-if="isLoading" class="absolute z-10 top-0 left-0 rounded-lg w-full h-full" />

      <s-heading-gradient :level="1" class="text-6xl text-center mx-auto mb-4"> Sign In </s-heading-gradient>

      <form class="flex flex-col gap-3" @submit.prevent="login">
        <div class="flex flex-col">
          <s-form-label>Username</s-form-label>
          <s-input-text v-model="username" name="username" required placeholder="adminusername" />
        </div>

        <div class="flex flex-col">
          <s-form-label>Password</s-form-label>
          <s-input-password v-model="password" name="password" required placeholder="adminpassword" />
        </div>

        <s-form-message v-if="loginError" class="h-16 mt-1" purpose="danger">
          {{ loginError }}
        </s-form-message>

        <s-button class="h-16 mt-1" type="submit">Login</s-button>
      </form>
    </u-card>
  </main>
</template>

<style scoped lang="scss"></style>
