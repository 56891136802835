import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SHeading = _resolveComponent("SHeading")!
  const _component_SFormLabel = _resolveComponent("SFormLabel")!
  const _component_SInputText = _resolveComponent("SInputText")!
  const _component_SInputArea = _resolveComponent("SInputArea")!
  const _component_SButton = _resolveComponent("SButton")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_SHeading, {
      class: "text-4xl",
      level: 1
    }, {
      default: _withCtx(() => [
        _createTextVNode("Broadcast Notification")
      ]),
      _: 1
    }),
    _createElementVNode("form", {
      class: "flex flex-col gap-4",
      onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_SFormLabel, { required: "" }, {
          default: _withCtx(() => [
            _createTextVNode("Title")
          ]),
          _: 1
        }),
        _createVNode(_component_SInputText, {
          modelValue: _ctx.title,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.title) = $event)),
          placeholder: "Enter notification title...",
          maxlength: "255",
          required: ""
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_SFormLabel, { required: "" }, {
          default: _withCtx(() => [
            _createTextVNode("Description")
          ]),
          _: 1
        }),
        _createVNode(_component_SInputArea, {
          modelValue: _ctx.description,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.description) = $event)),
          placeholder: "Enter notification description...",
          maxlength: "512",
          required: ""
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SFormLabel, null, {
          default: _withCtx(() => [
            _createTextVNode("Link?")
          ]),
          _: 1
        }),
        _createVNode(_component_SInputText, {
          modelValue: _ctx.link,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.link) = $event)),
          placeholder: "Enter notification link...",
          maxlength: "512"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_SFormLabel, null, {
          default: _withCtx(() => [
            _createTextVNode("Usernames (comma separated) (leave blank for all users)")
          ]),
          _: 1
        }),
        _createVNode(_component_SInputArea, {
          modelValue: _ctx.users,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.users) = $event)),
          placeholder: "Enter usernames..."
        }, null, 8, ["modelValue"])
      ]),
      _createVNode(_component_SButton, {
        class: "h-16",
        disabled: _ctx.isLoading,
        type: "submit"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Broadcast Notification ")
        ]),
        _: 1
      }, 8, ["disabled"])
    ], 32)
  ]))
}