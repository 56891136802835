<script lang="ts">
import { defineComponent } from "vue";

import SSpinnerDots from "scuffed-ui/src/components/Spinner/SSpinnerDots/SSpinnerDots.vue";
import SToastController from "scuffed-ui/src/components/Toast/SToastController/SToastController.vue";
import { useStore } from "./store/main";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "App",
  components: {
    SSpinnerDots,
    SToastController,
  },
  setup() {
    const store = useStore();
    const { isConnected } = storeToRefs(store);

    const router = useRouter();

    router.beforeEach((g) => {
      if (!store.isAuthed && g.path !== "/") router.replace("/");
    });

    return {
      isConnected,
    };
  },
});
</script>

<template>
  <s-spinner-dots
    v-if="!isConnected"
    class="absolute z-50 top-0 left-0 w-full h-full"
  />

  <router-view />

  <teleport to="body">
    <s-toast-controller class="z-50 left-0" />
  </teleport>
</template>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#app {
  width: 100vw;
  height: 100%;
  overscroll-behavior-y: contain;
  overflow: hidden;
}

#app {
  position: fixed;
  display: flex;
  padding: 0.5rem;
  background: linear-gradient(180deg, #bf251d 0%, #921913 100%);

  @media screen and (min-width: 900px) {
    padding: 1rem;
  }
}

::-webkit-scrollbar {
  display: none;
}

.scrollbar {
  &::-webkit-scrollbar {
    display: block;
    width: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background: theme("colors.neutral.200");
    border-radius: 5rem;
  }

  &::-webkit-scrollbar-thumb {
    background: theme("colors.neutral.400");
    border-radius: 5rem;
  }
}
</style>
