import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex gap-4 mt-4" }
const _hoisted_2 = { class: "flex flex-col flex-grow" }
const _hoisted_3 = { class: "flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SHeading = _resolveComponent("SHeading")!
  const _component_SFormLabel = _resolveComponent("SFormLabel")!
  const _component_SInputText = _resolveComponent("SInputText")!
  const _component_SButton = _resolveComponent("SButton")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_SHeading, {
      class: "text-4xl",
      level: 1
    }, {
      default: _withCtx(() => [
        _createTextVNode("Broadcast Toast")
      ]),
      _: 1
    }),
    _createElementVNode("form", {
      class: "flex flex-col gap-4",
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.broadcastToast && _ctx.broadcastToast(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_SFormLabel, null, {
            default: _withCtx(() => [
              _createTextVNode("Text")
            ]),
            _: 1
          }),
          _createVNode(_component_SInputText, {
            modelValue: _ctx.toast.text,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.toast.text) = $event)),
            placeholder: "Enter toast text...",
            maxlength: "120"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_SFormLabel, null, {
            default: _withCtx(() => [
              _createTextVNode("Duration (ms)")
            ]),
            _: 1
          }),
          _createVNode(_component_SInputText, {
            modelValue: _ctx.toast.duration,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.toast.duration) = $event)),
            type: "number",
            placeholder: "Enter toast duration..."
          }, null, 8, ["modelValue"])
        ])
      ]),
      _createVNode(_component_SButton, {
        class: "h-16",
        disabled: _ctx.disableToast,
        type: "submit"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Broadcast Toast ")
        ]),
        _: 1
      }, 8, ["disabled"])
    ], 32)
  ]))
}