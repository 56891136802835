<script lang="ts">
import { useStore } from "@/store/main";
import SButton from "scuffed-ui/src/components/Button/SButton/SButton.vue";
import SFormLabel from "scuffed-ui/src/components/Form/SFormLabel/SFormLabel.vue";
import SHeading from "scuffed-ui/src/components/Heading/SHeading/SHeading.vue";
import SInputText from "scuffed-ui/src/components/Input/SInputText/SInputText.vue";
import { defineComponent, reactive, ref } from "vue";

export default defineComponent({
  name: "UBroadcastToast",
  components: { SInputText, SFormLabel, SHeading, SButton },
  setup() {
    const store = useStore();

    const toast = reactive({
      text: "🚨 [ALERT] SERVER WILL RESTART SHORTLY FOR UPDATE DEPLOYMENT 🚨",
      duration: 10000,
    });

    const disableToast = ref(false);
    const broadcastToast = () => {
      disableToast.value = true;

      store.socket?.emit("admin:broadcast-toast", toast, () => {
        disableToast.value = false;
        alert("Toast broadcasted!");
      });
    };

    return {
      toast,
      disableToast,
      broadcastToast,
    };
  },
});
</script>

<template>
  <section>
    <SHeading class="text-4xl" :level="1">Broadcast Toast</SHeading>

    <form class="flex flex-col gap-4" @submit.prevent="broadcastToast">
      <div class="flex gap-4 mt-4">
        <div class="flex flex-col flex-grow">
          <SFormLabel>Text</SFormLabel>
          <SInputText
            v-model="toast.text"
            placeholder="Enter toast text..."
            maxlength="120"
          />
        </div>

        <div class="flex flex-col">
          <SFormLabel>Duration (ms)</SFormLabel>
          <SInputText
            v-model="toast.duration"
            type="number"
            placeholder="Enter toast duration..."
          />
        </div>
      </div>

      <SButton class="h-16" :disabled="disableToast" type="submit">
        Broadcast Toast
      </SButton>
    </form>
  </section>
</template>

<style lang="scss" scoped></style>
