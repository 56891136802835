<script lang="ts">
import { Icon } from "@iconify/vue";
import { computed, defineComponent, onBeforeMount, ref, watch } from "vue";
import { UserReportModel, ShopReportModel } from "@shared/models/Reports";
import { getShopItems, getShopReport, getUserReport } from "@/api/scuffedGames";
import ToastController from "scuffed-ui/src/components/Toast/SToastController/ToastController";
import Toast from "scuffed-ui/src/components/Toast/SToastController/Toast";
import SSpinnerDots from "scuffed-ui/src/components/Spinner/SSpinnerDots/SSpinnerDots.vue";
import VueApexCharts from "vue3-apexcharts";
import SInputText from "scuffed-ui/src/components/Input/SInputText/SInputText.vue";
import SInputSelect from "scuffed-ui/src/components/Input/SInputSelect/SInputSelect.vue";
import SFormLabel from "scuffed-ui/src/components/Form/SFormLabel/SFormLabel.vue";
import { ShopItemModel, ShopItemType } from "@shared/models/Shop";

import userIcon from "@iconify-icons/feather/user";
import calendarIcon from "@iconify-icons/feather/calendar";
import activeIcon from "@iconify-icons/feather/activity";
import purchaseIcon from "@iconify-icons/feather/shopping-bag";
import spentIcon from "@iconify-icons/feather/dollar-sign";

export default defineComponent({
  name: "UReports",
  components: {
    Icon,
    SSpinnerDots,
    apexchart: VueApexCharts,
    SFormLabel,
    SInputText,
    SInputSelect,
  },
  setup() {
    const userReport = ref<UserReportModel>();

    const shopReport = ref<ShopReportModel>();
    const shopItems = ref<ShopItemModel[]>([]);
    const shopItemsMap = computed(() => Object.fromEntries(shopItems.value.map((v) => [v.id, v])));

    const shopTotalSpent = computed(() => {
      if (!shopItems.value || !shopReport.value) return 0;

      let total = 0;
      for (const id in shopReport.value.shopItemPurchaseCounts) {
        const count = shopReport.value.shopItemPurchaseCounts[id];
        const item = shopItemsMap.value[id];
        if (!item) continue;

        total += count * item.price;
      }

      return total;
    });

    const shopTypePurchaseCounts = computed(() => {
      if (!shopItems.value || !shopReport.value) return {} as Record<ShopItemType, number>;

      const counts: Record<ShopItemType, number> = {} as any;

      for (const id in shopReport.value.shopItemPurchaseCounts) {
        const count = shopReport.value.shopItemPurchaseCounts[id];
        const item = shopItemsMap.value[id];
        if (!item) continue;

        counts[item.type] = (counts[item.type] || 0) + count;
      }

      return counts;
    });

    const daysAgo = ref("30");

    watch(
      daysAgo,
      async () => {
        const res = await getUserReport(parseInt(daysAgo.value.toString()) || 1);
        if (!res.success) {
          ToastController.addToast(new Toast(`Failed to get user report. Error: ${res.reason}`, true, 3000));
        } else {
          userReport.value = res.data;
        }
      },
      { immediate: true }
    );

    onBeforeMount(async () => {
      const res = await getShopReport();
      if (!res.success) {
        ToastController.addToast(new Toast(`Failed to get shop report. Error: ${res.reason}`, true, 3000));
      } else {
        shopReport.value = res.data;
      }

      const shopRes = await getShopItems();
      if (!shopRes.success) {
        ToastController.addToast(new Toast(`Failed to get shop items. Error: ${shopRes.reason}`, true, 3000));
      } else {
        shopItems.value = shopRes.data;
      }
    });

    const shopItemSearch = ref("");
    const shopItemSearchKey = ref("name");

    const shopItemSortKey = ref("");
    const shopItemSortDir = ref<"asc" | "desc">("asc");

    const changeSortKey = (key: string) => {
      if (shopItemSortKey.value === key) {
        if (shopItemSortDir.value === "asc") {
          shopItemSortDir.value = "desc";
        } else {
          shopItemSortKey.value = "";
        }
      } else {
        shopItemSortKey.value = key;
        shopItemSortDir.value = "asc";
      }
    };

    const currentShopItems = computed(() => {
      if (!shopItems.value) return [];

      const filtered = shopItems.value.filter((i) =>
        i[shopItemSearchKey.value as keyof ShopItemModel]
          ?.toString()
          .toLowerCase()
          .includes(shopItemSearch.value.toLowerCase())
      );

      if (!shopItemSortKey.value) return filtered;

      if (shopItemSortKey.value === "purchases") {
        return filtered.sort((a, b) => {
          const aCount = shopReport.value?.shopItemPurchaseCounts[a.id] || 0;
          const bCount = shopReport.value?.shopItemPurchaseCounts[b.id] || 0;

          return shopItemSortDir.value === "asc" ? aCount - bCount : bCount - aCount;
        });
      } else if (shopItemSortKey.value === "equipped") {
        return filtered.sort((a, b) => {
          const aCount = shopReport.value?.shopItemEquippedCounts[a.id] || 0;
          const bCount = shopReport.value?.shopItemEquippedCounts[b.id] || 0;

          return shopItemSortDir.value === "asc" ? aCount - bCount : bCount - aCount;
        });
      } else {
        return filtered.sort((a, b) => {
          const aVal = a[shopItemSortKey.value as keyof ShopItemModel]?.toString() ?? "";
          const bVal = b[shopItemSortKey.value as keyof ShopItemModel]?.toString() ?? "";

          return shopItemSortDir.value === "asc" ? (aVal > bVal ? 1 : -1) : aVal < bVal ? 1 : -1;
        });
      }
    });

    const shopItemTableHeadings = [
      { key: "name", label: "Name" },
      { key: "displayName", label: "Display Name" },
      { key: "description", label: "Description" },
      { key: "type", label: "Type" },
      { key: "price", label: "Price" },
      { key: "purchases", label: "Purchases" },
      { key: "equipped", label: "Equipped" },
    ];

    return {
      daysAgo,
      userReport,
      shopReport,
      shopItems,
      shopTotalSpent,
      shopTypePurchaseCounts,
      shopItemSearch,
      shopItemSearchKey,
      shopItemSortKey,
      shopItemSortDir,
      changeSortKey,
      currentShopItems,
      shopItemTableHeadings,

      userIcon,
      calendarIcon,
      activeIcon,
      purchaseIcon,
      spentIcon,
    };
  },
});
</script>

<template>
  <div class="flex flex-col py-8 px-4 h-full">
    <div
      v-if="!userReport || !shopReport || !shopItems"
      class="w-full h-full flex justify-center items-center"
    >
      <SSpinnerDots />
    </div>

    <div v-else class="pb-16 flex flex-col">
      <div class="flex gap-1 flex-col mb-12">
        <SFormLabel>Days Ago</SFormLabel>
        <SInputText v-model="daysAgo" type="number" min="1" class="w-40" />
      </div>

      <div class="flex justify-around">
        <div class="flex items-center justify-center gap-4">
          <Icon :icon="userIcon" class="text-5xl text-primary-500" />

          <div class="flex flex-col gap-2 relative">
            <p class="text-3xl font-bold text-neutral-900">{{ userReport.totalUsers }} Total Users</p>
            <p class="absolute top-full mt-1.5 text-xl font-semibold text-neutral-400">
              {{ userReport.onlineUsers }} Users Online
            </p>
          </div>
        </div>

        <div class="flex items-center justify-center gap-4">
          <Icon :icon="calendarIcon" class="text-5xl text-secondary-500" />

          <div class="flex flex-col gap-2 relative">
            <p class="text-3xl font-bold text-neutral-900">
              {{ userReport.newUsersThisPeriod }} Users Last {{ daysAgo }} Days
            </p>
            <p class="absolute top-full mt-1.5 text-xl font-semibold text-neutral-400">
              {{ userReport.newUsersLastPeriod }} Users Previous {{ daysAgo }} Days
            </p>
            <p class="absolute top-full mt-9 text-xl font-semibold text-neutral-400">
              {{ Math.round(userReport.newUsersThisPeriod / parseInt(daysAgo)) }} Users Per Day
            </p>
          </div>
        </div>

        <div class="flex items-center justify-center gap-4">
          <Icon :icon="activeIcon" class="text-5xl text-blue-500" />

          <div class="flex flex-col gap-2 relative">
            <p class="text-3xl font-bold text-neutral-900">
              {{ userReport.activeUsersThisPeriod }} Active Last {{ daysAgo }} Days
            </p>
            <p class="absolute top-full mt-1.5 text-xl font-semibold text-neutral-400">
              {{ userReport.activeUsersLastPeriod }} Active Previous {{ daysAgo }} Days
            </p>
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-1 mt-24">
        <p class="text-3xl font-bold text-neutral-900">Users Created In Last {{ daysAgo }} Days</p>

        <apexchart
          width="100%"
          height="480"
          type="line"
          :series="[
            {
              name: 'Users Created Per Day',
              data: userReport.usersCreatedPerDay?.map((v) => ({
                x: new Date(v.date).toDateString() + ' GMT',
                y: v.count,
              })),
            },
            {
              name: 'Users Created Per Day Last Period',
              data: userReport.usersCreatedPerDay?.map((v) => ({
                x: new Date(v.date).toDateString() + ' GMT',
                y: v.prevCount,
              })),
            },
          ]"
          :options="{
            xaxis: {
              type: 'datetime',
            },
          }"
        />
      </div>

      <div class="flex justify-around mt-20">
        <div class="flex items-center justify-center gap-4">
          <Icon :icon="purchaseIcon" class="text-5xl text-primary-500" />

          <div class="flex flex-col gap-2 relative">
            <p class="text-3xl font-bold text-neutral-900">{{ shopReport.totalPurchases }} Total Purchases</p>
          </div>
        </div>

        <div class="flex items-center justify-center gap-4">
          <Icon :icon="spentIcon" class="text-5xl text-secondary-500" />

          <div class="flex flex-col gap-2 relative">
            <p class="text-3xl font-bold text-neutral-900">{{ shopTotalSpent }} Coins Spent</p>
          </div>
        </div>
      </div>

      <table class="mt-12">
        <thead>
          <tr>
            <th>Type</th>
            <th>Purchases</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="[type, count] of Object.entries(shopTypePurchaseCounts)" :key="type">
            <td>{{ type }}</td>
            <td>{{ count || 0 }}</td>
          </tr>
        </tbody>
      </table>

      <div class="flex gap-4 mt-8">
        <SInputText v-model="shopItemSearch" placeholder="Search Shop Items" class="w-full max-w-xl" />
        <SInputSelect v-model="shopItemSearchKey" :options="['name', 'displayName', 'description', 'type']" />
      </div>

      <table class="mt-8">
        <thead>
          <tr>
            <th
              v-for="heading in shopItemTableHeadings"
              :key="heading.key"
              :class="{
                sort: shopItemSortKey === heading.key,
                asc: shopItemSortKey === heading.key && shopItemSortDir === 'asc',
              }"
              @click="changeSortKey(heading.key)"
            >
              {{ heading.label }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="item in currentShopItems" :key="item.id">
            <td>{{ item.name }}</td>
            <td>{{ item.displayName }}</td>
            <td class="text-ellipsis whitespace-nowrap overflow-hidden">
              {{ item.description }}
            </td>
            <td>{{ item.type }}</td>
            <td>
              {{ item.price }}
            </td>
            <td>
              {{ shopReport.shopItemPurchaseCounts[item.id] || 0 }}
            </td>
            <td>
              {{ shopReport.shopItemEquippedCounts[item.id] || 0 }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
table {
  width: 100%;

  thead {
    th {
      padding-bottom: 1.5rem;
      text-align: left;
      cursor: pointer;

      &.sort {
        @apply text-primary-500;

        &::after {
          content: "▼";
          margin-left: 0.5rem;
        }

        &.asc::after {
          content: "▲";
        }
      }
    }
  }

  td {
    padding-bottom: 1.5rem;
    padding-right: 1rem;
  }
}
</style>
