import { useStore } from "@/store/main";
import io from "socket.io-client";

export const createSocket = () => {
  const SERVER_URL =
    process.env.VUE_APP_SERVER_URL ?? "https://server.scuffeduno.online";

  const socket = io(SERVER_URL);

  const store = useStore();
  store.socket = socket;

  // track connection status
  socket.on("connect", () => (store.isConnected = true));
  socket.on("disconnect", () => (store.isConnected = false));
};
