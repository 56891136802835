<script lang="ts">
import { defineComponent } from "vue";
import UBroadcastToast from "./components/UBroadcastToast.vue";
// import UGameInfo from "./components/UGameInfo.vue";
import UBroadcastNotification from "./components/UBroadcastNotification.vue";
import UCreateReward from "./components/UCreateReward.vue";
import USendEmail from "./components/USendEmail.vue";

export default defineComponent({
  name: "UAdminTools",
  components: {
    UBroadcastToast,
    UBroadcastNotification,
    UCreateReward,
    USendEmail,
    // UGameInfo,
  },
  setup() {
    return {};
  },
});
</script>

<template>
  <div class="flex flex-col w-full pr-6 gap-8">
    <UBroadcastToast />
    <UBroadcastNotification />
    <UCreateReward />
    <USendEmail />
    <!-- <UGameInfo /> -->
  </div>
</template>

<style lang="scss" scoped></style>
