import { GameDisplayName } from "./Game";

export enum NotificationType {
  FRIEND_REQUEST_RECEIVED = "FRIEND_REQUEST_RECEIVED",
  FRIEND_REQUEST_ACCEPTED = "FRIEND_REQUEST_ACCEPTED",
  MESSAGE_RECEIVED = "MESSAGE_RECEIVED",
  GAME_INVITE_RECEIVED = "GAME_INVITE_RECEIVED",
  ACHIEVEMENT_COMPLETED = "ACHIEVEMENT_COMPLETED",
  ALERT_RECEIVED = "ALERT_RECEIVED",
}

export interface UserNotificationData {
  userId: number;
  username: string;
}

export interface FriendRequestReceivedNotificationData extends UserNotificationData {}

export interface FriendRequestAcceptedNotificationData extends UserNotificationData {}

export interface MessageReceivedNotificationData extends UserNotificationData {}

export interface GameInviteReceivedNotificationData extends UserNotificationData {
  gameName: GameDisplayName;
  roomId: string;
}

export interface AchievementCompletedNotificationData {
  achievementId: number;
  achievementName: string;
}

export interface AlertReceivedNotificationData {
  gameName: GameDisplayName;
  link?: string;
}

export type NotificationData =
  | FriendRequestReceivedNotificationData
  | FriendRequestAcceptedNotificationData
  | MessageReceivedNotificationData
  | GameInviteReceivedNotificationData
  | AchievementCompletedNotificationData
  | AlertReceivedNotificationData;

export interface NotificationModel {
  id: number;
  userId: number;
  title: string;
  description: string;
  type: NotificationType;
  data: NotificationData;
  isArchived: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface CreateNotificationModal {
  title?: string;
  description?: string;
  type: NotificationType;
  data: NotificationData;
  isArchived?: boolean;
  users?: string[];
}
