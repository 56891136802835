<script lang="ts">
import { defineComponent, onBeforeMount, ref } from "vue";
import { useIntervalFn } from "@vueuse/core";
import { useStore } from "@/store/main";

import { Icon } from "@iconify/vue";
import roomIcon from "@iconify-icons/feather/server";
import playerIcon from "@iconify-icons/feather/user";

import SSpinnerDots from "scuffed-ui/src/components/Spinner/SSpinnerDots/SSpinnerDots.vue";
import SHeading from "scuffed-ui/src/components/Heading/SHeading/SHeading.vue";
import UCard from "@/components/shared/UCard.vue";

export default defineComponent({
  name: "UOnlineCounts",
  components: {
    Icon,
    SSpinnerDots,
    SHeading,
    UCard,
  },
  setup() {
    const store = useStore();
    const isLoading = ref(true);

    const players = ref(0);
    const rooms = ref(0);

    const interval = useIntervalFn(() => {
      store.socket?.emit("admin:get-online", (p: number, r: number) => {
        players.value = p;
        rooms.value = r;
        isLoading.value = false;
      });
    }, 1000);

    return {
      isLoading,
      players,
      rooms,

      icons: {
        player: playerIcon,
        room: roomIcon,
      },
    };
  },
});
</script>

<template>
  <u-card class="p-4 flex items-center justify-around relative">
    <s-spinner-dots
      v-if="isLoading"
      class="absolute top-0 left-0 rounded-lg z-10 w-full h-full"
    />

    <div class="flex items-center gap-4">
      <Icon
        class="w-12 h-12 max-h-full text-primary-600"
        :icon="icons.player"
      />
      <s-heading :level="1" class="text-5xl">{{ players }} Online</s-heading>
    </div>

    <div class="flex items-center gap-4">
      <Icon
        class="w-12 h-12 max-h-full text-secondary-600"
        :icon="icons.room"
      />
      <s-heading :level="1" class="text-5xl">{{ rooms }} Online</s-heading>
    </div>
  </u-card>
</template>

<style lang="scss" scoped></style>
