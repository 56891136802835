import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6d6e2dda"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dashboard" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_s_heading = _resolveComponent("s-heading")!
  const _component_u_card = _resolveComponent("u-card")!
  const _component_u_online_counts = _resolveComponent("u-online-counts")!
  const _component_u_user_list = _resolveComponent("u-user-list")!
  const _component_u_room_list = _resolveComponent("u-room-list")!
  const _component_u_message_list = _resolveComponent("u-message-list")!
  const _component_u_news = _resolveComponent("u-news")!
  const _component_u_reports = _resolveComponent("u-reports")!
  const _component_u_admin_tools = _resolveComponent("u-admin-tools")!
  const _component_u_tabbed_card = _resolveComponent("u-tabbed-card")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createVNode(_component_u_card, { class: "p-4 flex items-center user" }, {
      default: _withCtx(() => [
        _createVNode(_component_s_heading, {
          level: 1,
          class: "text-5xl text-ellipsis overflow-hidden max-w-full whitespace-nowrap"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Welcome, " + _toDisplayString(_ctx.username), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_u_online_counts, { class: "count" }),
    _createVNode(_component_u_tabbed_card, {
      class: "main",
      tabs: _ctx.mainTabs
    }, {
      users: _withCtx(() => [
        _createVNode(_component_u_user_list)
      ]),
      rooms: _withCtx(() => [
        _createVNode(_component_u_room_list)
      ]),
      messages: _withCtx(() => [
        _createVNode(_component_u_message_list)
      ]),
      news: _withCtx(() => [
        _createVNode(_component_u_news)
      ]),
      reports: _withCtx(() => [
        _createVNode(_component_u_reports)
      ]),
      tools: _withCtx(() => [
        _createVNode(_component_u_admin_tools)
      ]),
      _: 1
    }, 8, ["tabs"])
  ]))
}