import { defineStore } from "pinia";
import { ref } from "vue";
import { Socket } from "socket.io-client";
import { UserModel } from "@shared/models/User";

export const useStore = defineStore("main", () => {
  const socket = ref<Socket>();
  const isConnected = ref(false);

  const socketEmitWithAck = <T>(event: string, ...args: any[]): Promise<T> => {
    return new Promise((resolve, reject) => {
      if (!socket.value) return reject("socket not connected");

      socket.value.emit(event, ...args, (data: T) => resolve(data));
    });
  };

  const waitForSocketConnection = (timeout = 5000) => {
    if (isConnected.value) return Promise.resolve();

    return new Promise<void>((resolve) => {
      const interval = setInterval(() => {
        if (isConnected.value) {
          clearInterval(interval);
          resolve();
        }
      }, 100);

      setTimeout(() => {
        clearInterval(interval);
        resolve();
      }, timeout);
    });
  };

  const isAuthed = ref(false);
  const username = ref("");

  const user = ref<UserModel | null>(null);
  const isLoggingIn = ref(false);

  return {
    socket,
    isConnected,
    socketEmitWithAck,
    waitForSocketConnection,

    isAuthed,
    username,

    user,
    isLoggingIn,
  };
});
