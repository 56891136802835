import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full h-full flex justify-center items-center" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = { class: "flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_s_spinner_dots = _resolveComponent("s-spinner-dots")!
  const _component_s_heading_gradient = _resolveComponent("s-heading-gradient")!
  const _component_s_form_label = _resolveComponent("s-form-label")!
  const _component_s_input_text = _resolveComponent("s-input-text")!
  const _component_s_input_password = _resolveComponent("s-input-password")!
  const _component_s_form_message = _resolveComponent("s-form-message")!
  const _component_s_button = _resolveComponent("s-button")!
  const _component_u_card = _resolveComponent("u-card")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createVNode(_component_u_card, { class: "p-4 dt:p-8 max-w-xl w-full relative" }, {
      default: _withCtx(() => [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_s_spinner_dots, {
              key: 0,
              class: "absolute z-10 top-0 left-0 rounded-lg w-full h-full"
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_s_heading_gradient, {
          level: 1,
          class: "text-6xl text-center mx-auto mb-4"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Sign In ")
          ]),
          _: 1
        }),
        _createElementVNode("form", {
          class: "flex flex-col gap-3",
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_s_form_label, null, {
              default: _withCtx(() => [
                _createTextVNode("Username")
              ]),
              _: 1
            }),
            _createVNode(_component_s_input_text, {
              modelValue: _ctx.username,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
              name: "username",
              required: "",
              placeholder: "adminusername"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_s_form_label, null, {
              default: _withCtx(() => [
                _createTextVNode("Password")
              ]),
              _: 1
            }),
            _createVNode(_component_s_input_password, {
              modelValue: _ctx.password,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
              name: "password",
              required: "",
              placeholder: "adminpassword"
            }, null, 8, ["modelValue"])
          ]),
          (_ctx.loginError)
            ? (_openBlock(), _createBlock(_component_s_form_message, {
                key: 0,
                class: "h-16 mt-1",
                purpose: "danger"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.loginError), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_s_button, {
            class: "h-16 mt-1",
            type: "submit"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Login")
            ]),
            _: 1
          })
        ], 32)
      ]),
      _: 1
    })
  ]))
}