<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { ChatMessage, Room } from "@/gameTypes";

import SInputText from "scuffed-ui/src/components/Input/SInputText/SInputText.vue";
import SButton from "scuffed-ui/src/components/Button/SButton/SButton.vue";
import { useStore } from "@/store/main";

export default defineComponent({
  name: "URoomChat",
  components: { SInputText, SButton },
  props: {
    room: {
      type: Object as () => Room,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const room = computed(() => props.room);
    const chat = computed(() => [...room.value.chat].reverse());
    const yourId = computed(() => `admin_${store.username.toLowerCase()}`);

    const message = ref("");
    const sendMessage = () => {
      const socket = store.socket;
      if (!socket || !room.value) return;

      if (message.value.length === 0 || message.value.length > 300) return;

      const m: ChatMessage = {
        text: message.value,
        username: `(Admin) ${store.username}`,
        id: yourId.value,
        time: Date.now(),
      };

      socket.emit("admin:room-message", room.value.id, m);
      message.value = "";
    };

    return {
      chat,
      yourId,

      message,
      sendMessage,
    };
  },
});
</script>

<template>
  <div class="w-full h-full flex flex-col gap-3 dt:gap-5">
    <!-- chat -->
    <div
      class="w-full h-[60vh] dt:h-full scrollbar overflow-y-scroll overflow-x-hidden pr-3 dt:pr-5 flex flex-col-reverse gap-4"
    >
      <div
        v-for="(m, i) in chat"
        :key="i"
        class="p-4 rounded-lg w-max max-w-[45%] min-w-[16rem] relative mb-5"
        :class="{
          'bg-secondary-500 mr-auto': m.id !== yourId,
          'bg-primary-600 ml-auto': m.id === yourId,
        }"
      >
        <p class="text-neutral-50 break-words">{{ m.text }}</p>
        <p
          class="absolute top-full text-neutral-600 text-sm font-medium"
          :class="{ 'right-0': m.id !== yourId, 'left-0': m.id === yourId }"
        >
          {{ m.username }},
          {{ new Date(m.time).toTimeString().substr(0, 5) }}
        </p>
      </div>
    </div>

    <form class="flex gap-3" @submit.prevent="sendMessage">
      <s-input-text
        class="flex-grow text-lg py-4 min-w-0"
        v-model="message"
        name="message"
        placeholder="Type message here..."
        maxlength="300"
      />
      <s-button type="submit">Send</s-button>
    </form>
  </div>
</template>

<style lang="scss" scoped></style>
