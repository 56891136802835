<script lang="ts">
import { defineComponent, ref } from "vue";
import SButton from "scuffed-ui/src/components/Button/SButton/SButton.vue";
import SFormLabel from "scuffed-ui/src/components/Form/SFormLabel/SFormLabel.vue";
import SHeading from "scuffed-ui/src/components/Heading/SHeading/SHeading.vue";
import SInputSelect from "scuffed-ui/src/components/Input/SInputSelect/SInputSelect.vue";
import SInputArea from "scuffed-ui/src/components/Input/SInputArea/SInputArea.vue";
import ToastController from "scuffed-ui/src/components/Toast/SToastController/ToastController";
import Toast from "scuffed-ui/src/components/Toast/SToastController/Toast";
import { EmailName } from "@shared/models/AdminEmail";
import { sendAdminEmail } from "@/api/scuffedGames";
import SInputText from "scuffed-ui/src/components/Input/SInputText/SInputText.vue";

export default defineComponent({
  name: "USendEmail",
  components: {
    SHeading,
    SButton,
    SFormLabel,
    SInputSelect,
    SInputArea,
    SInputText,
  },
  setup() {
    const users = ref("");
    const emailName = ref(EmailName.SCUFFED_UNO_TEAM_MODE_UPDATE);
    const daysSinceLastActive = ref("");

    const isLoading = ref(false);

    const sendEmails = async () => {
      isLoading.value = true;

      const res = await sendAdminEmail(
        emailName.value,
        users.value.trim() ? users.value.trim().split(",") : undefined,
        parseInt(daysSinceLastActive.value) || undefined
      );
      if (res.success) {
        ToastController.addToast(new Toast(`Sent ${res.data} emails.`, true, 3000));
      } else {
        ToastController.addToast(new Toast(`Failed to send emails. Reason: ${res.reason}`, true, 3000));
      }

      isLoading.value = false;
    };

    return {
      users,
      emailName,
      daysSinceLastActive,
      isLoading,

      sendEmails,

      EmailName,
    };
  },
});
</script>

<template>
  <section>
    <SHeading class="text-4xl" :level="1">Send Emails</SHeading>

    <form class="flex flex-col gap-4" @submit.prevent="sendEmails">
      <div class="flex flex-col flex-grow">
        <SFormLabel required>Email Name</SFormLabel>
        <SInputSelect v-model="emailName" :options="Object.keys(EmailName)" required />
      </div>

      <div class="flex flex-col">
        <SFormLabel>Usernames (comma separated) (this or days since last active)</SFormLabel>
        <SInputArea v-model="users" type="string" placeholder="Enter usernames..." />
      </div>

      <div class="flex flex-col">
        <SFormLabel>Days Since Last Active (this or usernames)</SFormLabel>
        <SInputText
          v-model="daysSinceLastActive"
          type="number"
          placeholder="Enter days since last active..."
        />
      </div>

      <SButton class="h-16" type="submit"> {{ isLoading ? "Loading..." : "Send Emails" }} </SButton>
    </form>
  </section>
</template>

<style lang="scss" scoped></style>
