<script lang="ts">
import { defineComponent, ref } from "vue";
import { useStore } from "@/store/main";
import { Player, Room } from "@/gameTypes";

import { Icon } from "@iconify/vue";
import plusIcon from "@iconify-icons/feather/plus";
import crownIcon from "@iconify-icons/akar-icons/crown";
import userIcon from "@iconify-icons/feather/user";
import editIcon from "@iconify-icons/feather/edit";

import SButton from "scuffed-ui/src/components/Button/SButton/SButton.vue";
import SModal from "scuffed-ui/src/components/Modal/SModal/SModal.vue";
import SInputText from "scuffed-ui/src/components/Input/SInputText/SInputText.vue";
import SFormLabel from "scuffed-ui/src/components/Form/SFormLabel/SFormLabel.vue";
import SHeadingGradient from "scuffed-ui/src/components/Heading/SHeadingGradient/SHeadingGradient.vue";

export default defineComponent({
  name: "URoomPlayers",
  components: {
    Icon,
    SButton,
    SModal,
    SInputText,
    SFormLabel,
    SHeadingGradient,
  },
  props: {
    room: {
      type: Object as () => Room,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const editUsernamePlayer = ref<Player>();
    const editUsername = ref("");

    const changeUsername = () => {
      if (!editUsernamePlayer.value) return;
      store.socket?.emit(
        "admin:room-username",
        props.room.id,
        editUsernamePlayer.value.id,
        editUsername.value
      );

      editUsernamePlayer.value = undefined;
    };

    const kickPlayer = (p: Player) => {
      store.socket?.emit("admin:room-kick", props.room.id, p.id);
    };

    return {
      editUsernamePlayer,
      editUsername,
      changeUsername,
      kickPlayer,

      icons: {
        plus: plusIcon,
        user: userIcon,
        crown: crownIcon,
        edit: editIcon,
      },
    };
  },
});
</script>

<template>
  <div class="w-full h-full scrollbar overflow-y-scroll pr-3 dt:pr-5">
    <div class="players-grid gap-3 dt:gap-5">
      <div
        v-for="p in room.players"
        :key="p.id"
        class="rounded-lg bg-neutral-200 grid grid-cols-1 grid-rows-[3.5rem_1fr_3.5rem] gap-1 place-items-center p-4"
        :class="{
          'text-neutral-600': p.id !== room.host.id,
          'text-secondary-500': p.id === room.host.id,
        }"
      >
        <div class="flex items-center justify-center gap-2 relative">
          <p
            class="text-2xl font-semibold text-ellipsis overflow-hidden whitespace-nowrap max-w-[15ch]"
          >
            {{ p.username }}
          </p>

          <button
            class="outline-none w-5 h-5"
            @click="
              editUsernamePlayer = p;
              editUsername = p.username;
            "
          >
            <Icon :icon="icons.edit" class="text-neutral-600 w-full h-full" />
          </button>
        </div>

        <Icon
          :icon="p.id === room.host.id ? icons.crown : icons.user"
          class="w-24 h-24"
        />

        <div class="flex justify-center items-center gap-3 h-3/4">
          <s-button purpose="danger" @click="kickPlayer(p)" class="h-full">
            Kick Player
          </s-button>
        </div>
      </div>

      <!-- <div
        v-if="room.players.length < room.settings.maxPlayers"
        class="rounded-lg bg-neutral-200 flex justify-center items-center flex-col text-neutral-500 hover:text-neutral-600 transition-colors duration-300 cursor-pointer"
        @click="store.socket?.emit('admin:room-add-bot')"
      >
        <Icon :icon="icons.plus" class="w-24 h-24" />
        <p class="font-semibold text-3xl">Add Bot</p>
      </div> -->
    </div>

    <s-modal
      :open="!!editUsernamePlayer"
      @close="editUsernamePlayer = undefined"
      class="flex flex-col items-center max-w-xl w-full gap-4"
    >
      <s-heading-gradient :level="1" class="text-4xl">
        Edit Username
      </s-heading-gradient>

      <form @submit.prevent="changeUsername" class="flex flex-col gap-4 w-full">
        <div class="flex flex-col">
          <s-form-label for="username">Username</s-form-label>
          <s-input-text
            name="username"
            placeholder="New username..."
            v-model="editUsername"
            minlength="2"
            maxlength="20"
            size="20"
          />
        </div>

        <s-button type="submit" class="h-16">Change Username</s-button>
      </form>
    </s-modal>
  </div>
</template>

<style lang="scss" scoped>
.players-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
  grid-auto-rows: minmax(16rem, 1fr);

  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  }
}
</style>
