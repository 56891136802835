import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = { class: "flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SHeading = _resolveComponent("SHeading")!
  const _component_SFormLabel = _resolveComponent("SFormLabel")!
  const _component_SInputSelect = _resolveComponent("SInputSelect")!
  const _component_SInputArea = _resolveComponent("SInputArea")!
  const _component_SInputText = _resolveComponent("SInputText")!
  const _component_SButton = _resolveComponent("SButton")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_SHeading, {
      class: "text-4xl",
      level: 1
    }, {
      default: _withCtx(() => [
        _createTextVNode("Send Emails")
      ]),
      _: 1
    }),
    _createElementVNode("form", {
      class: "flex flex-col gap-4",
      onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.sendEmails && _ctx.sendEmails(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_SFormLabel, { required: "" }, {
          default: _withCtx(() => [
            _createTextVNode("Email Name")
          ]),
          _: 1
        }),
        _createVNode(_component_SInputSelect, {
          modelValue: _ctx.emailName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.emailName) = $event)),
          options: Object.keys(_ctx.EmailName),
          required: ""
        }, null, 8, ["modelValue", "options"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_SFormLabel, null, {
          default: _withCtx(() => [
            _createTextVNode("Usernames (comma separated) (this or days since last active)")
          ]),
          _: 1
        }),
        _createVNode(_component_SInputArea, {
          modelValue: _ctx.users,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.users) = $event)),
          type: "string",
          placeholder: "Enter usernames..."
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SFormLabel, null, {
          default: _withCtx(() => [
            _createTextVNode("Days Since Last Active (this or usernames)")
          ]),
          _: 1
        }),
        _createVNode(_component_SInputText, {
          modelValue: _ctx.daysSinceLastActive,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.daysSinceLastActive) = $event)),
          type: "number",
          placeholder: "Enter days since last active..."
        }, null, 8, ["modelValue"])
      ]),
      _createVNode(_component_SButton, {
        class: "h-16",
        type: "submit"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.isLoading ? "Loading..." : "Send Emails"), 1)
        ]),
        _: 1
      })
    ], 32)
  ]))
}