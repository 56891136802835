import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex h-full gap-4 dt:gap-8 justify-between w-full" }
const _hoisted_2 = { class: "scrollbar w-full flex-grow overflow-y-scroll" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_u_tab_button = _resolveComponent("u-tab-button")!
  const _component_u_card = _resolveComponent("u-card")!

  return (_openBlock(), _createBlock(_component_u_card, { class: "grid grid-cols-1 grid-rows-[2.5rem_1fr] dt:grid-rows-[4rem_1fr] w-full p-3 dt:p-5 gap-3 dt:gap-5 max-h-full" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
          return (_openBlock(), _createBlock(_component_u_tab_button, {
            key: tab.route || tab.name,
            icon: tab.icon,
            selected: _ctx.active.name === tab.name,
            onClick: ($event: any) => (_ctx.active = tab)
          }, null, 8, ["icon", "selected", "onClick"]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, _ctx.active.name)
      ])
    ]),
    _: 3
  }))
}