export enum RewardType {
  XP = "XP",
  COINS = "COINS",
  SHOP_ITEM = "SHOP_ITEM",
}

export type XpRewardData = { xp: number };
export type CoinsRewardData = { coins: number };
export type ShopItemRewardData = { shopItemId: number };

export type RewardData = XpRewardData | CoinsRewardData | ShopItemRewardData;

export interface RewardModel {
  id: number;
  userId: number;
  type: RewardType;
  data: RewardData;
  claimed: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface MiniRewardModel {
  type: RewardType;
  data: RewardData;
}

export interface CreateRewardModel {
  userId: number;
  type: RewardType;
  data: RewardData;
}
