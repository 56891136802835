<script lang="ts">
import { defineComponent, ref } from "vue";
import { storeToRefs } from "pinia";
import { useStore } from "@/store/main";

import usersIcon from "@iconify-icons/feather/user";
import roomsIcon from "@iconify-icons/feather/server";
import messagesIcon from "@iconify-icons/feather/mail";
import toolsIcon from "@iconify-icons/feather/settings";
import newsIcon from "@iconify-icons/feather/rss";
import reportsIcon from "@iconify-icons/feather/bar-chart";

import SHeading from "scuffed-ui/src/components/Heading/SHeading/SHeading.vue";
import UCard from "@/components/shared/UCard.vue";
import UOnlineCounts from "@/components/app/UOnlineCounts.vue";
import UTabbedCard, { Tab } from "@/components/shared/UTabbedCard.vue";
import UUserList from "@/components/app/UUserList.vue";
import URoomList from "@/components/app/URoomList.vue";
import UMessageList from "@/components/app/UMessageList.vue";
import UAdminTools from "@/components/app/UAdminTools/UAdminTools.vue";
import UNews from "@/components/app/UNews.vue";
import UReports from "@/components/app/UReports.vue";

export default defineComponent({
  name: "Dashboard",
  components: {
    SHeading,
    UCard,
    UOnlineCounts,
    UTabbedCard,
    UUserList,
    URoomList,
    UMessageList,
    UAdminTools,
    UNews,
    UReports,
  },
  setup() {
    const store = useStore();
    const { username } = storeToRefs(store);

    const mainTabs = ref<Tab[]>([
      {
        name: "users",
        icon: usersIcon,
      },
      {
        name: "rooms",
        icon: roomsIcon,
      },
      {
        name: "messages",
        icon: messagesIcon,
      },
      {
        name: "news",
        icon: newsIcon,
      },
      {
        name: "reports",
        icon: reportsIcon,
      },
      {
        name: "tools",
        icon: toolsIcon,
      },
    ]);

    return {
      username,
      mainTabs,
    };
  },
});
</script>

<template>
  <main class="dashboard">
    <u-card class="p-4 flex items-center user">
      <s-heading :level="1" class="text-5xl text-ellipsis overflow-hidden max-w-full whitespace-nowrap">
        Welcome, {{ username }}
      </s-heading>
    </u-card>

    <u-online-counts class="count" />

    <u-tabbed-card class="main" :tabs="mainTabs">
      <template v-slot:users>
        <u-user-list />
      </template>

      <template v-slot:rooms>
        <u-room-list />
      </template>

      <template v-slot:messages>
        <u-message-list />
      </template>

      <template v-slot:news>
        <u-news />
      </template>

      <template v-slot:reports>
        <u-reports />
      </template>

      <template v-slot:tools>
        <u-admin-tools />
      </template>
    </u-tabbed-card>
  </main>
</template>

<style lang="scss" scoped>
.dashboard {
  width: 100%;
  height: calc(100vh - 2rem);
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 5rem calc(100% - 6rem);
  grid-template-areas:
    "user user user user user count count count count count count count"
    "main main main main main main main main main main main main";

  .user {
    grid-area: user;
  }

  .count {
    grid-area: count;
  }

  .main {
    grid-area: main;
  }
}
</style>
