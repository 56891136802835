<script lang="ts">
import { defineComponent, onBeforeMount, ref, watch } from "vue";
import { useIntervalFn } from "@vueuse/core";
import formatDuration from "format-duration";
import { useStore } from "@/store/main";
import { User } from "@/gameTypes";

import SSpinnerDots from "scuffed-ui/src/components/Spinner/SSpinnerDots/SSpinnerDots.vue";
import SButtonText from "scuffed-ui/src/components/Button/SButtonText/SButtonText.vue";
import SModal from "scuffed-ui/src/components/Modal/SModal/SModal.vue";
import { UserModel } from "@shared/models/User";
import { getUser } from "@/api/scuffedGames";
import ToastController from "scuffed-ui/src/components/Toast/SToastController/ToastController";
import Toast from "scuffed-ui/src/components/Toast/SToastController/Toast";

export default defineComponent({
  name: "UUserList",
  components: {
    SSpinnerDots,
    SButtonText,
    SModal,
  },
  setup() {
    const store = useStore();
    const isLoading = ref(true);

    const users = ref<User[]>([]);
    const openJSON = ref<User>();
    const openUser = ref<User>();

    const user = ref<UserModel>();
    watch(openUser, async (newUser) => {
      if (!newUser || newUser.scuffedGamesUserId === null) {
        user.value = undefined;
        return;
      }

      const res = await getUser(newUser.scuffedGamesUserId);
      if (!res.success) {
        ToastController.addToast(new Toast("Failed to get user", true, 3000));
        user.value = undefined;
        return;
      }

      user.value = res.data;
    });

    useIntervalFn(
      () => {
        store.socket?.emit("admin:get-users", (u: User[]) => {
          users.value = u;
          isLoading.value = false;

          if (openJSON.value)
            openJSON.value = users.value.find(
              (u) => u.socket.id === openJSON.value?.socket.id
            );
        });
      },
      1000,
      { immediate: true }
    );

    return {
      isLoading,
      users,
      openJSON,
      openUser,
      user,

      formatDuration,
    };
  },
});
</script>

<template>
  <div class="relative flex flex-col w-full h-full">
    <s-spinner-dots
      v-if="isLoading"
      class="absolute top-0 left-0 z-10 w-full h-full"
    />

    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Admin</th>
          <th>Connected</th>
          <th>Time Since Creation</th>
          <th>Room ID</th>
          <th>Username</th>
          <th>Scuffed Games</th>
          <th>Actions</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="user in users" :key="user.socket.id">
          <td>{{ user.socket.id }}</td>
          <td>{{ user.isAdmin }}</td>
          <td>{{ user.socket.connected }}</td>
          <td>
            {{
              formatDuration(Date.now() - user.creationTime, { leading: true })
            }}
          </td>
          <td>{{ user.player?.roomId || "N/A" }}</td>
          <td>{{ user.player?.username || "N/A" }}</td>
          <td>
            <s-button-text
              v-if="user.scuffedGamesUserId !== null"
              @click="openUser = user"
            >
              View User
            </s-button-text>
            <p v-else>{{ "N/A" }}</p>
          </td>
          <td class="flex flex-row justify-center px-3 gap-3 flex-wrap">
            <s-button-text @click="openJSON = user"> JSON </s-button-text>
          </td>
        </tr>
      </tbody>
    </table>

    <s-modal
      class="max-w-5xl w-full h-[50rem] max-h-full"
      :open="!!openJSON"
      @close="openJSON = undefined"
    >
      <pre class="p-4 overflow-y-scroll h-full scrollbar">
        {{ openJSON ? JSON.stringify(openJSON, null, 2) : "" }}
      </pre>
    </s-modal>

    <s-modal
      class="max-w-5xl w-full h-[50rem] max-h-full"
      :open="!!openUser"
      @close="openUser = undefined"
    >
      <pre class="p-4 overflow-y-scroll h-full scrollbar">
        {{ user ? JSON.stringify(user, null, 2) : "Loading..." }}
      </pre>
    </s-modal>
  </div>
</template>

<style lang="scss" scoped>
table {
  td {
    text-align: center;
    padding: 0.5rem 0;
  }
}
</style>
