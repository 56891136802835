<script lang="ts">
import { defineComponent, onBeforeMount, ref } from "vue";
import { useIntervalFn } from "@vueuse/core";
import { useStore } from "@/store/main";
import { Room } from "@/gameTypes";

import SSpinnerDots from "scuffed-ui/src/components/Spinner/SSpinnerDots/SSpinnerDots.vue";
import SButton from "scuffed-ui/src/components/Button/SButton/SButton.vue";
import SButtonText from "scuffed-ui/src/components/Button/SButtonText/SButtonText.vue";
import SModal from "scuffed-ui/src/components/Modal/SModal/SModal.vue";
import ToastController from "scuffed-ui/src/components/Toast/SToastController/ToastController";
import Toast from "scuffed-ui/src/components/Toast/SToastController/Toast";
import {
  deleteContactFormSubmission,
  getContactFormSubmissions,
} from "@/api/scuffedGames";
import { ContactFormSubmission } from "@shared/models/ContactForm";

export default defineComponent({
  name: "URoomList",
  components: {
    SSpinnerDots,
    SButton,
    SButtonText,
    SModal,
  },
  setup() {
    const store = useStore();
    const isLoading = ref(true);

    const messages = ref<ContactFormSubmission[]>([]);
    const openMessage = ref<ContactFormSubmission>();

    const deleteMessage = async (id: number) => {
      const res = await deleteContactFormSubmission(id);
      if (!res.success) {
        ToastController.addToast(
          new Toast("Failed to delete message", true, 3000)
        );
        return;
      }

      messages.value = res.data;
    };

    const getMessages = async () => {
      isLoading.value = true;
      const res = await getContactFormSubmissions();
      isLoading.value = false;

      if (!res.success) {
        ToastController.addToast(
          new Toast("Failed to fetch messages", true, 3000)
        );
        return;
      }

      messages.value = res.data;
    };

    onBeforeMount(getMessages);

    return {
      isLoading,
      messages,
      openMessage,
      deleteMessage,
    };
  },
});
</script>

<template>
  <div class="relative flex flex-col w-full h-full">
    <s-spinner-dots
      v-if="isLoading"
      class="absolute top-0 left-0 z-10 w-full h-full"
    />

    <table>
      <thead>
        <tr>
          <th>Email</th>
          <th>Subject</th>
          <th>Message</th>
          <th>Date</th>
          <th>Actions</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="m in messages" :key="m.id">
          <td>{{ m.email }}</td>
          <td>{{ m.subject }}</td>
          <td class="text-ellipsis whitespace-nowrap overflow-hidden">
            {{ decodeURI(m.message) }}
          </td>
          <td>{{ new Date(m.createdAt).toUTCString() }}</td>
          <td>
            <s-button-text class="mb-0.5 mr-4" @click="openMessage = m">
              Open
            </s-button-text>

            <s-button-text
              class="mb-0.5"
              purpose="danger"
              @click="deleteMessage(m.id)"
            >
              Delete
            </s-button-text>
          </td>
        </tr>
      </tbody>
    </table>

    <s-modal
      class="max-w-5xl w-full h-[50rem] max-h-full overflow-y-scroll flex flex-col"
      :open="!!openMessage"
      @close="openMessage = undefined"
    >
      <p class="text-lg">
        <span class="font-bold">Email: </span>
        {{ openMessage?.email }}
      </p>

      <p class="text-lg">
        <span class="font-bold">Subject: </span>
        {{ openMessage?.subject }}
      </p>

      <p class="text-lg break-words whitespace-pre-line flex-grow">
        <span class="font-bold">Message: <br /></span>
        <span
          class="block"
          v-text="decodeURI(openMessage?.message || '')"
        ></span>
      </p>

      <a
        class="ml-auto"
        :href="`mailto:${openMessage?.email}`"
        target="_blank"
        rel="noreferrer noopener"
      >
        <s-button class="h-16">Reply</s-button>
      </a>
    </s-modal>
  </div>
</template>

<style lang="scss" scoped>
table {
  tr {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 1fr 1fr;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }

  td {
    text-align: center;
  }
}
</style>
