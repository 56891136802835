import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6a54290b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full h-full scrollbar overflow-y-scroll pr-3 dt:pr-5" }
const _hoisted_2 = { class: "players-grid gap-3 dt:gap-5" }
const _hoisted_3 = { class: "flex items-center justify-center gap-2 relative" }
const _hoisted_4 = { class: "text-2xl font-semibold text-ellipsis overflow-hidden whitespace-nowrap max-w-[15ch]" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "flex justify-center items-center gap-3 h-3/4" }
const _hoisted_7 = { class: "flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_s_button = _resolveComponent("s-button")!
  const _component_s_heading_gradient = _resolveComponent("s-heading-gradient")!
  const _component_s_form_label = _resolveComponent("s-form-label")!
  const _component_s_input_text = _resolveComponent("s-input-text")!
  const _component_s_modal = _resolveComponent("s-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.room.players, (p) => {
        return (_openBlock(), _createElementBlock("div", {
          key: p.id,
          class: _normalizeClass(["rounded-lg bg-neutral-200 grid grid-cols-1 grid-rows-[3.5rem_1fr_3.5rem] gap-1 place-items-center p-4", {
          'text-neutral-600': p.id !== _ctx.room.host.id,
          'text-secondary-500': p.id === _ctx.room.host.id,
        }])
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(p.username), 1),
            _createElementVNode("button", {
              class: "outline-none w-5 h-5",
              onClick: ($event: any) => {
              _ctx.editUsernamePlayer = p;
              _ctx.editUsername = p.username;
            }
            }, [
              _createVNode(_component_Icon, {
                icon: _ctx.icons.edit,
                class: "text-neutral-600 w-full h-full"
              }, null, 8, ["icon"])
            ], 8, _hoisted_5)
          ]),
          _createVNode(_component_Icon, {
            icon: p.id === _ctx.room.host.id ? _ctx.icons.crown : _ctx.icons.user,
            class: "w-24 h-24"
          }, null, 8, ["icon"]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_s_button, {
              purpose: "danger",
              onClick: ($event: any) => (_ctx.kickPlayer(p)),
              class: "h-full"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Kick Player ")
              ]),
              _: 2
            }, 1032, ["onClick"])
          ])
        ], 2))
      }), 128))
    ]),
    _createVNode(_component_s_modal, {
      open: !!_ctx.editUsernamePlayer,
      onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.editUsernamePlayer = undefined)),
      class: "flex flex-col items-center max-w-xl w-full gap-4"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_s_heading_gradient, {
          level: 1,
          class: "text-4xl"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Edit Username ")
          ]),
          _: 1
        }),
        _createElementVNode("form", {
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.changeUsername && _ctx.changeUsername(...args)), ["prevent"])),
          class: "flex flex-col gap-4 w-full"
        }, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_s_form_label, { for: "username" }, {
              default: _withCtx(() => [
                _createTextVNode("Username")
              ]),
              _: 1
            }),
            _createVNode(_component_s_input_text, {
              name: "username",
              placeholder: "New username...",
              modelValue: _ctx.editUsername,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editUsername) = $event)),
              minlength: "2",
              maxlength: "20",
              size: "20"
            }, null, 8, ["modelValue"])
          ]),
          _createVNode(_component_s_button, {
            type: "submit",
            class: "h-16"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Change Username")
            ]),
            _: 1
          })
        ], 32)
      ]),
      _: 1
    }, 8, ["open"])
  ]))
}