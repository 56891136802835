<script lang="ts">
import { IconifyIcon } from "@iconify/vue";
import { defineComponent, ref } from "vue";

import UCard from "./UCard.vue";
import UTabButton from "./UTabButton.vue";

export interface Tab {
  route?: string;
  name: string;
  icon: IconifyIcon;
}

export default defineComponent({
  name: "UTabbedCard",
  components: { UCard, UTabButton },
  props: {
    tabs: {
      type: Array as () => Tab[],
      required: true,
    },
  },
  setup(props) {
    const active = ref(props.tabs[0]);

    return {
      active,
    };
  },
});
</script>

<template>
  <u-card
    class="grid grid-cols-1 grid-rows-[2.5rem_1fr] dt:grid-rows-[4rem_1fr] w-full p-3 dt:p-5 gap-3 dt:gap-5 max-h-full"
  >
    <div class="flex h-full gap-4 dt:gap-8 justify-between w-full">
      <u-tab-button
        v-for="tab in tabs"
        :key="tab.route || tab.name"
        :icon="tab.icon"
        :selected="active.name === tab.name"
        @click="active = tab"
      />
    </div>

    <div class="scrollbar w-full flex-grow overflow-y-scroll">
      <slot :name="active.name"></slot>
    </div>
  </u-card>
</template>

<style lang="scss" scoped></style>
