<script lang="ts">
import { defineComponent } from "vue";

import { Icon, IconifyIcon } from "@iconify/vue";

export default defineComponent({
  name: "UTabButton",
  components: {
    Icon,
  },
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Object as () => IconifyIcon,
    },
  },
});
</script>

<template>
  <button
    class="h-full flex-grow px-4 rounded-lg flex items-center justify-center transition-colors duration-300"
    :class="{
      'bg-neutral-300 text-neutral-500 hover:bg-neutral-400 hover:text-neutral-600':
        !selected,
      'bg-secondary-200 text-secondary-500 pointer-events-none': selected,
    }"
  >
    <Icon v-if="icon" :icon="icon" class="w-8 h-8" />
    <slot></slot>
  </button>
</template>

<style lang="scss" scoped></style>
