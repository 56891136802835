<script lang="ts">
import { defineComponent, ref } from "vue";
import SButton from "scuffed-ui/src/components/Button/SButton/SButton.vue";
import SFormLabel from "scuffed-ui/src/components/Form/SFormLabel/SFormLabel.vue";
import SHeading from "scuffed-ui/src/components/Heading/SHeading/SHeading.vue";
import SInputText from "scuffed-ui/src/components/Input/SInputText/SInputText.vue";
import SInputArea from "scuffed-ui/src/components/Input/SInputArea/SInputArea.vue";
import { broadcastNotification } from "@/api/scuffedGames";
import { GameDisplayName, GameName } from "@shared/models/Game";
import { NotificationType } from "@shared/models/Notifications";
import ToastController from "scuffed-ui/src/components/Toast/SToastController/ToastController";
import Toast from "scuffed-ui/src/components/Toast/SToastController/Toast";

export default defineComponent({
  name: "UBroadcastNotification",
  components: {
    SHeading,
    SButton,
    SFormLabel,
    SInputText,
    SInputArea,
  },
  setup() {
    const title = ref("");
    const description = ref("");
    const link = ref("");
    const users = ref("");

    const isLoading = ref(false);

    const handleSubmit = async () => {
      if (!title.value || !description.value) {
        return;
      }

      isLoading.value = true;

      const res = await broadcastNotification({
        type: NotificationType.ALERT_RECEIVED,
        title: title.value,
        description: description.value,
        data: {
          gameName: GameDisplayName.SCUFFED_UNO,
          link: link.value || undefined,
        },
        users: users.value ? users.value.split(",").map((u) => u.trim()) : undefined,
      });
      if (!res.success) {
        isLoading.value = false;
        ToastController.addToast(
          new Toast(`Failed to broadcast notification. Error: ${res.reason}`, true, 3000)
        );
        return;
      }

      ToastController.addToast(
        new Toast(`Broadcasted notification. Sent: ${res.data.sent}, Failed: ${res.data.failed}`, false, 3000)
      );

      isLoading.value = false;
    };

    return {
      title,
      description,
      link,
      users,
      isLoading,
      handleSubmit,
    };
  },
});
</script>

<template>
  <section>
    <SHeading class="text-4xl" :level="1">Broadcast Notification</SHeading>

    <form class="flex flex-col gap-4" @submit.prevent="handleSubmit">
      <div class="flex flex-col flex-grow">
        <SFormLabel required>Title</SFormLabel>
        <SInputText v-model="title" placeholder="Enter notification title..." maxlength="255" required />
      </div>

      <div class="flex flex-col">
        <SFormLabel required>Description</SFormLabel>
        <SInputArea
          v-model="description"
          placeholder="Enter notification description..."
          maxlength="512"
          required
        />
      </div>

      <div class="flex flex-col">
        <SFormLabel>Link?</SFormLabel>
        <SInputText v-model="link" placeholder="Enter notification link..." maxlength="512" />
      </div>

      <div class="flex flex-col">
        <SFormLabel>Usernames (comma separated) (leave blank for all users)</SFormLabel>
        <SInputArea v-model="users" placeholder="Enter usernames..." />
      </div>

      <SButton class="h-16" :disabled="isLoading" type="submit"> Broadcast Notification </SButton>
    </form>
  </section>
</template>

<style lang="scss" scoped></style>
